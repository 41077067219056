import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import "../styles/pages/contact-us-2025.scss"
const ContactUs = props => {
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "message",
      validateEmpty: "true",
      type: "textarea",
      label: "Message",
      col: "10",
      errorMessage: "Please enter a valid message",
    },
  ]
  const currentLocation = props.location

  return (
    <StaticQuery
      query={graphql`
        query contentContactUs2025 {
          SuperOps {
            pages(where: { title: "Contact us" }) {
              title
              pageBlock {
                id
                content {
                  html
                }
              }
              navigationBlock {
                name
                slug
                isDropdown
              }
              seo {
                title
                description
                keywords
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="contact-us">
          {data.SuperOps.pages.map(page => {
            const { title, pageBlock, navigationBlock, seo } = page
            return (
              <>
                <Frame
                  title={seo.title}
                  description={seo.description}
                  keywords={seo.keywords}
                  ogTitle={seo.title}
                  ogDescription={seo.description}
                  ogImage={seo.image}
                  ogType="website"
                >
                  <header>
                    <Navigation
                      page="Home"
                      dskScroll={400}
                      darkHelloBar
                      theme="dark"
                      darkTheme
                      btn1="secondary white"
                      btn2="primary white ml-9"
                    />
                  </header>

                  <Container>
                    <Row>
                      <Col lg={6}>
                        <div className="cn-left">
                          <div
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease-out-bounce"
                            data-sal-duration="700"
                          >
                            <p className="tag">CONTACT US</p>
                            <div className="alt p16">
                              {parse(pageBlock[9].content.html)}
                            </div>
                          </div>
                          <div className="details">
                            <div
                              data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <div className="altv2">
                                {parse(pageBlock[10].content.html)}
                              </div>
                            </div>
                            <div className="contact-details-card">
                              <div
                                data-sal="slide-up"
                                data-sal-delay="400"
                                data-sal-easing="ease-out-bounce"
                                data-sal-duration="700"
                              >
                                <Row>
                                  <Col xl={6}>
                                    {/* <img
                                      src={PinIcon}
                                      className="icon position-relative"
                                      height="5"
                                      width="5"
                                      alt="pin"
                                    /> */}
                                    <SVGIcon
                                      name="contactus/pin-icon"
                                      className="icon position-relative"
                                    />
                                    <span className="p16">
                                      {parse(pageBlock[3].content.html)}
                                    </span>
                                  </Col>
                                  <Col xl={6}>
                                    {/* <img
                                      src={PhoneIcon}
                                      className="icon position-relative"
                                      height="5"
                                      width="5"
                                      alt="phone"
                                    /> */}
                                    <SVGIcon
                                      name="contactus/phone"
                                      className="icon position-relative"
                                    />
                                    <span className="p16">
                                      {parse(pageBlock[7].content.html)}
                                      {parse(pageBlock[8].content.html)}
                                    </span>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl={6}>
                                    <div
                                      data-sal="slide-up"
                                      data-sal-delay="600"
                                      data-sal-easing="ease-out-bounce"
                                      data-sal-duration="700"
                                    >
                                      {/* <img
                                        src={EmailIcon}
                                        className="icon position-relative"
                                        height="5"
                                        width="5"
                                        alt="email"
                                      /> */}
                                      <SVGIcon
                                        name="contactus/email-icon"
                                        className="icon position-relative"
                                      />
                                      <span className="p16">
                                        {parse(pageBlock[4].content.html)}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          data-sal="slide-up"
                          data-sal-delay="700"
                          data-sal-easing="ease-out-bounce"
                          data-sal-duration="700"
                        >
                          <div className="cn-form">
                            <center>
                              <section className="cn-cta bts-cta-cnt">
                                <Container className="position-relative">
                                  <span className="info">
                                    <center>
                                      <span className="p16">
                                        {parse(pageBlock[2].content.html)}
                                      </span>
                                    </center>
                                  </span>
                                  <div className="cta-form forms">
                                    <FormBuilder
                                      formClassName="forms"
                                      errorClassName="error-message"
                                      inputErrorClassName="error-active"
                                      data={formdata}
                                      buttonText="SUBMIT"
                                      buttonClassName="primary-new"
                                      IpStackData
                                      endpoint={
                                        process.env
                                          .HUBSPOT_CONTACT_US_API_ENDPOINT
                                      }
                                      url={
                                        process.env.HUBSPOT_CONTACT_US_API_URL
                                      }
                                      formName="Contact us - Superops"
                                      path="contact-us"
                                    />
                                  </div>
                                  <span className="action">
                                    <center>
                                      <p>
                                        By clicking "SUBMIT", you agree to
                                        SuperOps's{" "}
                                        <Link to="/terms">Terms of use</Link>{" "}
                                        and{" "}
                                        <Link to="/privacy">
                                          Privacy policy
                                        </Link>
                                        .
                                      </p>
                                    </center>
                                  </span>
                                </Container>
                              </section>
                            </center>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <CTAThankYou />
                </Frame>
              </>
            )
          })}
        </div>
      )}
    />
  )
}

export default ContactUs
